import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import "./src/styles/index.scss";

import React from 'react';
import RootElement from './src/modules/common/components/root-element';

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>;
};
